import React, { useEffect, useState } from 'react';
import {
  RecentTransactionsContainer,
  RecentTransactionsTitle,
  RecentTransactionsTable,
  StyledTableHeader,
  StyledTableRow,
  StyledTableCell
} from './StyledFinancialOverview';

import { fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';



const FinancialOverview: React.FC = () => {

  const [orgId, setOrgId] = useState(0);
  const [jwtIdToken, setUserJwtIdToken] = useState('');
  const [expenseData, setExpenseData] = useState<ExpenseData | null>(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUserAttributes = await fetchUserAttributes();
        const orgIdValue = currentUserAttributes['custom:orgId'] || '';
        const orgIdNumber = parseInt(orgIdValue, 10);
        setOrgId(orgIdNumber);

        const currentAuthSession = await fetchAuthSession();
        if (currentAuthSession && currentAuthSession.tokens) {
          const { idToken } = currentAuthSession.tokens;
          if (idToken) {
            setUserJwtIdToken(idToken.toString());
          } else {
            console.log('ID Token is undefined');
          }
        } else {
          console.log('currentAuthSession or tokens are undefined');
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchUser();
  }, []);


  useEffect(() => {
    const fetchExpenses = async () => {
      try {

        if (orgId > 0) {
          const response = await axios.get(`https://api.irltech.io/getOrganizedExpenses?orgId=${orgId}`, {
            headers: {
              Authorization: `${jwtIdToken}`,
            },
          });

          setExpenseData(response.data); 
        };

      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };
    
    if (jwtIdToken && orgId) {
      fetchExpenses();
    }

    fetchExpenses();
  }, [jwtIdToken, orgId]);


  if (!orgId) {
    return <p>Loading transactions...</p>; 
  }

  type SubTypeData = {
    totalPlannedAmount: string;
    totalRealAmount: string;
    percentage: string;
  };
  
  type ExpenseTypeData = {
    [subType: string]: SubTypeData;
  };
  
  type MonthData = {
    [expenseType: string]: ExpenseTypeData;
  };
  
  type OrgData = {
    [month: string]: MonthData;
  };
  
  type ExpenseData = {
    [orgId: string]: OrgData;
  };

  type SubTypes = {
    [key: string]: string[];
  };
 
  const subTypes: SubTypes = {
    'Custo de investimento': ['Equipamentos', 'Construcao civil', 'Veiculos', 'Mobiliarios', 'Tecnologia da Informacao', 'Marketing'],
    'Custo fixo': ['Aluguel', 'Energia', 'Agua', 'Taxas', 'Telefonia e internet', 'Emprestimo', 'Limpeza e seguranca', 'Servicos recorrentes'],
    'Mao de obra': ['Salario', 'Horas extras', 'Encargos', 'Beneficios', 'Terceirizados', 'Prolabore + Retirada'],
    'Custos variaveis': ['Combustiveis', 'Produtos Limpeza Cafe Escritorio', 'Manutencao', 'Despesas de viagem', 'Tarifas Bancarias', 'Treinamento', 'Servicos Parceiros', 'Insumos', 'Frete'],
    'Material': ['Produtos'],
  };

  const monthMap: { [key: string]: string } = {
    "01": "Janeiro",
    "02": "Fevereiro",
    "03": "Março",
    "04": "Abril",
    "05": "Maio",
    "06": "Junho",
    "07": "Julho",
    "08": "Agosto",
    "09": "Setembro",
    "10": "Outubro",
    "11": "Novembro",
    "12": "Dezembro"
  };

  const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

  
  return (
      <RecentTransactionsContainer>
          <RecentTransactionsTitle>Despesas</RecentTransactionsTitle>
          <RecentTransactionsTable>
              <thead>
                  <StyledTableRow>
                      <StyledTableHeader></StyledTableHeader> {/* Empty Header for Type Column */}
                      <StyledTableHeader></StyledTableHeader> {/* Empty Header for Type Column */}

                      {months.map((month) => (
                          <StyledTableHeader colSpan={3} key={month}>{month}</StyledTableHeader>
                      ))}
                  </StyledTableRow>
                  <StyledTableRow>
                      <StyledTableHeader></StyledTableHeader> {/* Empty Header for Type Column */}
                      <StyledTableHeader></StyledTableHeader> {/* Empty Header for Type Column */}
                      {months.flatMap((month) => [
                          <StyledTableHeader key={`prev-${month}`}>Prev.</StyledTableHeader>,
                          <StyledTableHeader key={`perc-${month}`}>%</StyledTableHeader>,
                          <StyledTableHeader key={`real-${month}`}>Real</StyledTableHeader>,
                      ])}
                  </StyledTableRow>
              </thead>
              <tbody>
              {Object.entries(subTypes).map(([type, subTypesList]) => (
                <>
                  <StyledTableRow key={type}>
                    <StyledTableCell rowSpan={subTypesList.length + 1}>{type}</StyledTableCell>
                  </StyledTableRow>
                  {subTypesList.map(subType => (
                    <StyledTableRow key={subType}>
                      <StyledTableCell>{subType}</StyledTableCell>
                      {months.map(monthName => {
                        const monthNumber = Object.keys(monthMap).find(key => monthMap[key] === monthName) || '01'; // Default to '01' if not found
                        let monthData;
                        if (expenseData && expenseData[orgId] && expenseData[orgId][monthNumber]) {
                          monthData = expenseData[orgId][monthNumber][type]?.[subType];
                        }

                        return (
                          <React.Fragment key={`${subType}-${monthNumber}`}>
                            <StyledTableCell>{monthData?.totalPlannedAmount || '-'}</StyledTableCell>
                            <StyledTableCell>{monthData?.percentage || '-'}</StyledTableCell>
                            <StyledTableCell>{monthData?.totalRealAmount || '-'}</StyledTableCell>
                          </React.Fragment>
                        );
                      })}
                    </StyledTableRow>
                  ))}
                </>
              ))}


            </tbody>
          </RecentTransactionsTable>
        </RecentTransactionsContainer>
      );
  };
  
  export default FinancialOverview;
  