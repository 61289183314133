import React from 'react';
import Logo from '../../assets/logo.png'; // Adjust the path as necessary

import {
  SidebarContainer,
  SidebarHeader,
  SidebarMenu,
  SidebarLink,
  SidebarSection,
  SidebarSubmenu,
  SidebarMenuHeader
} from './StyledSidebar'; // adjust the import path as necessary

const Sidebar = () => {
  return (
    <SidebarContainer>
      <SidebarMenu>
        <SidebarHeader>
          <img src={Logo} alt="Logo" />
        </SidebarHeader>


        <SidebarSection>
          <SidebarMenuHeader>Dashboards</SidebarMenuHeader>
          <SidebarSubmenu> 
            <SidebarLink href="/">Prev x Real</SidebarLink>
          </SidebarSubmenu>
        </SidebarSection>

        <SidebarSection>
          <SidebarMenuHeader>Lançamentos</SidebarMenuHeader>

          <SidebarSubmenu>
            <SidebarLink href="/expenses">Despesas</SidebarLink>
          </SidebarSubmenu>
        </SidebarSection>

        <SidebarSection>
          <SidebarMenuHeader>Geral</SidebarMenuHeader>

          <SidebarSubmenu>
            <SidebarLink href="/overview">Sumário</SidebarLink>
          </SidebarSubmenu>
        </SidebarSection>


        {/* Add more sections as needed */}
      </SidebarMenu>
    </SidebarContainer>
  );
};

export default Sidebar;
