import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { Card, CardTitle, ChartContainer, DashboardContainer, LegendTable, LegendRow, LegendColorBox, LegendLabel, LegendValue } from './StyledBudgetSummary';
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';

// Define the interfaces for the API data
interface ExpenseCategoryDetail {
  totalPlannedAmount: string;
  totalRealAmount: string;
  percentage: string;
}

interface ExpenseCategory {
  [category: string]: {
    [item: string]: ExpenseCategoryDetail;
  };
}

interface ExpenseSummary {
  [orgId: string]: {
    [id: string]: ExpenseCategory;
  };
}

interface ChartDataItem {
  name: string;
  value: number;
  color: string;
  totalPlannedAmount: string;
  totalRealAmount: string;
  percentage: string;
}


interface ChartData {
  title: string;
  data: ChartDataItem[];
}

const defaultChartData: ChartData[] = [
  { 
    title: 'Custo de investimento', 
    data: [
      {
        name: 'Prev',
        value: 0,
        color: '#ccc',
        totalPlannedAmount: 'N/A',
        totalRealAmount: 'N/A',
        percentage: 'N/A'
      },
      {
        name: 'Real',
        value: 0,
        color: '#ccc',
        totalPlannedAmount: 'N/A',
        totalRealAmount: 'N/A',
        percentage: 'N/A'
      }
    ]  
  },
  { 
    title: 'Custo fixo', 
    data: [
      {
        name: 'Prev',
        value: 0,
        color: '#ccc',
        totalPlannedAmount: 'N/A',
        totalRealAmount: 'N/A',
        percentage: 'N/A'
      },
      {
        name: 'Real',
        value: 0,
        color: '#ccc',
        totalPlannedAmount: 'N/A',
        totalRealAmount: 'N/A',
        percentage: 'N/A'
      }
    ]  
  },
  { 
    title: 'Mao de obra', 
    data: [
      {
        name: 'Prev',
        value: 0,
        color: '#ccc',
        totalPlannedAmount: 'N/A',
        totalRealAmount: 'N/A',
        percentage: 'N/A'
      },
      {
        name: 'Real',
        value: 0,
        color: '#ccc',
        totalPlannedAmount: 'N/A',
        totalRealAmount: 'N/A',
        percentage: 'N/A'
      }
    ] 
  },
  { 
    title: 'Custos variaveis', 
    data: [
      {
        name: 'Prev',
        value: 0,
        color: '#ccc',
        totalPlannedAmount: 'N/A',
        totalRealAmount: 'N/A',
        percentage: 'N/A'
      },
      {
        name: 'Real',
        value: 0,
        color: '#ccc',
        totalPlannedAmount: 'N/A',
        totalRealAmount: 'N/A',
        percentage: 'N/A'
      }
    ] 
  },
  { 
    title: 'Material', 
    data: [
      {
        name: 'Prev',
        value: 0,
        color: '#ccc',
        totalPlannedAmount: 'N/A',
        totalRealAmount: 'N/A',
        percentage: 'N/A'
      },
      {
        name: 'Real',
        value: 0,
        color: '#ccc',
        totalPlannedAmount: 'N/A',
        totalRealAmount: 'N/A',
        percentage: 'N/A'
      }
    ] 
  },
];

const BudgetSummary: React.FC = () => {
  const [transactions, setTransactions] = useState<ExpenseSummary>({});
  const [orgId, setOrgId] = useState(0);
  const [jwtIdToken, setUserJwtIdToken] = useState('');
  const [chartData, setChartData] = useState<ChartData[]>([]);

  const transformApiDataToChartData = useCallback(
    (apiData: ExpenseSummary, orgId: number): ChartData[] => {


      const lightColors = ['#7FB3D5', '#76D7C4', '#EC7063', '#F5B041', '#AF7AC5', '#85C1E9', '#73C6B6', '#F1948A', '#F0B27A', '#D2B4DE', '#A9DFBF', '#AED6F1'];

      function assignColorsToSubTypes(chartDataMap: { [key: string]: ChartDataItem[] }): void {
        let colorIndex = 0;
      
        Object.keys(chartDataMap).forEach(expenseType => {
          chartDataMap[expenseType].forEach((chartDataItem: ChartDataItem) => {
            chartDataItem.color = lightColors[colorIndex % lightColors.length];
            colorIndex++;
          });
        });
      }
      

      const orgData = apiData[orgId.toString()];
      if (!orgData || Object.keys(orgData).length === 0) {
        return defaultChartData;
      }
  
      let chartDataMap: { [key: string]: ChartDataItem[] } = {};
  
      Object.keys(orgData).forEach(categoryId => {
        const categoryData = orgData[categoryId];
        Object.keys(categoryData).forEach(expenseType => {
          if (!chartDataMap[expenseType]) {
            chartDataMap[expenseType] = [];
          }
          const subCategoryData = categoryData[expenseType];
          Object.keys(subCategoryData).forEach(subCategoryKey => {
            const item = subCategoryData[subCategoryKey];
            chartDataMap[expenseType].push({
              name: subCategoryKey,
              value: item ? parseFloat(item.totalRealAmount.replace(/R\$\s?/g, '').replace(/\./g, '').replace(',', '.')) : 0,
              color: '#81C784', // Light green color
              totalPlannedAmount: item ? item.totalPlannedAmount : 'N/A',
              totalRealAmount: item ? item.totalRealAmount : 'N/A',
              percentage: item ? item.percentage : 'N/A'
            });
          });
        });
      });
  
      assignColorsToSubTypes(chartDataMap);
  
      let chartDataArray: ChartData[] = [];
      Object.keys(chartDataMap).forEach(expenseType => {
        chartDataArray.push({
          title: expenseType,
          data: chartDataMap[expenseType]
        });
      });
  
      return chartDataArray;
    },
    [] // Dependencies of this function
  );
  

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUserAttributes = await fetchUserAttributes();
        const orgIdValue = currentUserAttributes['custom:orgId'] || '';
        const orgIdNumber = parseInt(orgIdValue, 10);
        setOrgId(orgIdNumber);

        const currentAuthSession = await fetchAuthSession();
        if (currentAuthSession && currentAuthSession.tokens) {
          const { idToken } = currentAuthSession.tokens;
          if (idToken) {
            setUserJwtIdToken(idToken.toString());
          } else {
            console.log('ID Token is undefined');
          }
        } else {
          console.log('currentAuthSession or tokens are undefined');
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchUser();
  }, []);


  useEffect(() => {
    if (transactions && orgId) {
      const transformedData = transformApiDataToChartData(transactions, orgId);
      setChartData(transformedData);
    }
  }, [transformApiDataToChartData, transactions, orgId]);




  useEffect(() => {
    const fetchExpenses = async () => {
      try {

        if (orgId > 0) {
          const response = await axios.get(`https://api.irltech.io/getOrganizedExpenses?orgId=${orgId}`, {
            headers: {
              Authorization: `${jwtIdToken}`,
            },
          });

          setTransactions(response.data); 
        };

      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };
    
    if (jwtIdToken && orgId) {
      fetchExpenses();
    }

  }, [transformApiDataToChartData, jwtIdToken, orgId]);


  
  return (
    <DashboardContainer>
      {chartData.map((card, index) => (
        <Card key={index}>
          <CardTitle>{card.title}</CardTitle>
          <ChartContainer>
            <PieChart width={200} height={200}>
              <Pie
                data={card.data}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
              >
                {card.data.map((entry, entryIndex) => (
                  <Cell key={`cell-${entryIndex}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ChartContainer>
          <LegendTable>
            {card.data.map((entry, entryIndex) => (
              <LegendRow key={`legend-row-${entryIndex}`}>
                <LegendColorBox color={entry.color} />
                <LegendLabel>{entry.name}</LegendLabel>
                <LegendValue>
                  {entry.totalRealAmount}
                </LegendValue>
              </LegendRow>
            ))}
          </LegendTable>

        </Card>
      ))}
    </DashboardContainer>
  );
};

export default BudgetSummary;