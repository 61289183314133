import React from 'react';
import styled from 'styled-components';

const StyledStatementCard = styled.div.withConfig({
  shouldForwardProp: (propName) => propName !== 'borderLeftColor',
})<{ borderLeftColor: string }>`
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: white;
  margin-bottom: 20px;
  border-left: 4px solid ${props => props.borderLeftColor};
`;


type StatementCardProps = {
  isCurrent: boolean;
  children: React.ReactNode;
};

export const StatementCard: React.FC<StatementCardProps> = ({ isCurrent, children }) => {
  return (
    <StyledStatementCard borderLeftColor={isCurrent ? '#2E7D32' : 'grey'}>
      {children}
    </StyledStatementCard>
  );
};


export const StatementInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
  column-gap: 20px;
`;

export const StatementRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StatementTitle = styled.h2`
  margin-bottom: 15px;
`;
