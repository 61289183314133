import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';

import { fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth';

type ExpenseSummary = {
  [orgId: string]: {
    [month: string]: {
      totalPlannedAmount: string;
      totalRealAmount: string;
    };
  };
};

const monthNames: { [key: string]: string } = {
  "01": "Jan",
  "02": "Fev",
  "03": "Mar",
  "04": "Abr",
  "05": "Mai",
  "06": "Jun",
  "07": "Jul",
  "08": "Ago",
  "09": "Set",
  "10": "Out",
  "11": "Nov",
  "12": "Dez"
};


// Styled component for the chart container
const ChartContainer = styled.div`
  padding: 20px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: auto; // Allows for scrolling if content overflows
  font-size: 0.9em; // Further reduce the overall font size

  .recharts-cartesian-axis-tick { // Target axis tick labels
    font-size: 0.9em; // Further reduce the font size of axis labels
  }
`;

const IncomeExpensesChart: React.FC = () => {



  const [transactions, setTransactions] = useState<ExpenseSummary>({}); // Single object, not an array
  const [orgId, setOrgId] = useState(0);
  const [jwtIdToken, setUserJwtIdToken] = useState('');


  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUserAttributes = await fetchUserAttributes();
        const orgIdValue = currentUserAttributes['custom:orgId'] || '';
        const orgIdNumber = parseInt(orgIdValue, 10);
        setOrgId(orgIdNumber);

        const currentAuthSession = await fetchAuthSession();
        if (currentAuthSession && currentAuthSession.tokens) {
          const { idToken } = currentAuthSession.tokens;
          if (idToken) {
            setUserJwtIdToken(idToken.toString());
          } else {
            console.log('ID Token is undefined');
          }
        } else {
          console.log('currentAuthSession or tokens are undefined');
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    const fetchExpenses = async () => {
      try {

        if (orgId > 0) {
          const response = await axios.get(`https://api.irltech.io/getMonthlySummary?orgId=${orgId}`, {
            headers: {
              Authorization: `${jwtIdToken}`,
            },
          });

          setTransactions(response.data); 
        };

      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };
    
    if (jwtIdToken && orgId) {
      fetchExpenses();
    }

  }, [jwtIdToken, orgId]);

  const processData = (expenseSummary: ExpenseSummary) => {
    const monthlyData = Array.from({ length: 12 }, (_, i) => {
      const monthNumber = (i + 1).toString().padStart(2, '0'); // Pad the month number to two digits
      return {
        name: monthNames[monthNumber], // Use the padded month number to get the month name
        Previsto: 0,
        Real: 0
      };
    });
  
    const orgData = expenseSummary[orgId] || {};  
    Object.keys(orgData).forEach(month => {
      const monthIndex = parseInt(month, 10) - 1;
      if (monthIndex >= 0 && monthIndex < 12) {
        monthlyData[monthIndex].Previsto = parseFloat(orgData[month].totalPlannedAmount.replace('R$', '').replace(',', '.'));
        monthlyData[monthIndex].Real = parseFloat(orgData[month].totalRealAmount.replace('R$', '').replace(',', '.'));
      } else {
        console.log(`Invalid month index for month: ${month}`); // Log any invalid month indices
      }
    });
    
    return monthlyData;
  };
  
  
  const chartData = processData(transactions);

  return (
    <ChartContainer>
      <ResponsiveContainer width="100%" height={280}>
        <BarChart
          data={chartData}
          margin={{
            top: 5, right: 20, left: 20, bottom: 10,
          }}
          barGap={0}
          barCategoryGap={20}
        >
          <CartesianGrid strokeDasharray="0 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend style={{ color: 'black' }} />
          <Bar dataKey="Previsto" fill="#acacac" radius={[10, 10, 9, 9]} />
          <Bar dataKey="Real" fill="#2E7D32" radius={[10, 10, 9, 9]} />
        </BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default IncomeExpensesChart;