import styled from 'styled-components';

export const HeaderContainer = styled.header`
  background: #fafafa;
  color: #1e2b37;
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const OrgInfo = styled.div`

`;
