import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  RecentTransactionsContainer,
  RecentTransactionsTable,
  TableHeader,
  TableCell,
  TableRow,
  HeaderContainer,
  RecentTransactionsTitle,
  AddTransactionButton
} from './StyledRecentTransactions';

import { useNavigate } from 'react-router-dom';


import { fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth';


type Transaction = {
  id: string,
  date: string,
  expenseSubType: string,
  expenseType: string,
  month: string,
  orgId: string,
  percentage: string,
  plannedAmount: string,
  realAmount: string,
  timestamp: string,
  username: string
 };

const RecentTransactionsList = () => {

  const navigate = useNavigate();

  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [orgId, setOrgId] = useState(0);
  const [jwtIdToken, setUserJwtIdToken] = useState('');

  const handleButtonClick = () => {
    navigate('/entries');
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUserAttributes = await fetchUserAttributes();
        const orgIdValue = currentUserAttributes['custom:orgId'] || '';
        const orgIdNumber = parseInt(orgIdValue, 10);
        setOrgId(orgIdNumber);

        const currentAuthSession = await fetchAuthSession();
        if (currentAuthSession && currentAuthSession.tokens) {
          const { idToken } = currentAuthSession.tokens;
          if (idToken) {
            setUserJwtIdToken(idToken.toString());
          } else {
            console.log('ID Token is undefined');
          }
        } else {
          console.log('currentAuthSession or tokens are undefined');
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    const fetchExpenses = async () => {
      try {

        if (orgId > 0) {
          const response = await axios.get(`https://api.irltech.io/getExpenses?orgId=${orgId}`, {
            headers: {
              Authorization: `${jwtIdToken}`,
            },
          });

          setTransactions(response.data); 
        };

      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };
    
    if (jwtIdToken && orgId) {
      fetchExpenses();
    }

    fetchExpenses();
  }, [jwtIdToken, orgId]);


  if (!orgId) {
    return <p>Loading transactions...</p>; // Display a loading message
  }

  return (
    <RecentTransactionsContainer>
      <HeaderContainer>
        <RecentTransactionsTitle>Despesas</RecentTransactionsTitle>
        <AddTransactionButton onClick={handleButtonClick}>
          Add Despesa
        </AddTransactionButton>
      </HeaderContainer>
      <RecentTransactionsTable>
        <thead>
          <TableRow>
            <TableHeader>Data da despesa</TableHeader>
            <TableHeader>Mes da despesa</TableHeader>
            <TableHeader>Tipo da despesa</TableHeader>
            <TableHeader>Sub-tipo da despesa</TableHeader>
            <TableHeader>Valor planejado</TableHeader>
            <TableHeader>Valor real</TableHeader>
            <TableHeader>Data do cadastro</TableHeader>
            <TableHeader>Usuário do cadastro</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {transactions.map((transaction) => (
            <TableRow key={transaction.id}>
              <TableCell>{transaction.date}</TableCell>
              <TableCell>{transaction.month}</TableCell>
              <TableCell>{transaction.expenseType}</TableCell>
              <TableCell>{transaction.expenseSubType}</TableCell>
              <TableCell>{transaction.plannedAmount}</TableCell>
              <TableCell>{transaction.realAmount}</TableCell>
              <TableCell>{transaction.timestamp}</TableCell>
              <TableCell>{transaction.username}</TableCell>
            </TableRow>
          ))}
        </tbody>
      </RecentTransactionsTable>
    </RecentTransactionsContainer>
  );
};

export default RecentTransactionsList;
