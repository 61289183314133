import styled from 'styled-components';
import { Field } from 'formik';


export const FormContainer = styled.div`
  padding: 20px;
  background: #f5f5f5;
  border-radius: 8px;
  max-width: 100vh; 
  margin: 20px auto; 
`;

export const FormGroup = styled.div`
  margin-bottom: 15px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

export const Select = styled(Field).attrs({ as: 'select' })`
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 10px;
`;

export const Input = styled(Field)`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const SubmitButton = styled.button`
  display: flex;
  justify-content: center;
  width: 20%;

  
  padding: 10px 15px;
  background-color: #1e2b37;
  color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #2d4458;
  }
`;

export const ButtonFormGroup = styled(FormGroup)`
  display: flex;
  justify-content: flex-end;
`;


export const StyledSelect = styled(Field)`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const ErrorMessage = styled.div`
  color: red; 
  margin-top: 4px;
`;