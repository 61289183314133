import styled from 'styled-components';

export const StyledDashboard = styled.div`
  display: grid;
  grid-template-rows: auto 1fr; /* Header and main content */
  gap: 10px;
  padding: 20px;
  background-color: #fafafa;
`;

export const DashboardHeader = styled.header`
  grid-row: 1;
  padding: 10px;
`;

export const DashboardMain = styled.main`
  display: grid;
  grid-row: 2;
  gap: 10px;
  grid-template-columns: repeat(12, 1fr); /* 12-column grid */
  grid-auto-rows: minmax(100px, auto);
`;

export const StatementsSection = styled.section`
  grid-column: span 6; /* Half width */
`;

export const AccountBalanceSection = styled.section`
  grid-column: span 6; /* Half width */
`;

export const RecentTransactionsSection = styled.section`
  grid-column: span 12;
`;

export const BudgetSummarySection = styled.section`
  grid-column: span 12; 
`;

// Media queries for responsiveness
export const responsiveDashboard = styled.div`
  @media (max-width: 768px) {
    ${StatementsSection},
    ${AccountBalanceSection} {
      grid-column: 1 / -1; /* Full width on smaller screens */
    }
  }
`;
