import React, { useEffect, useState } from 'react';
import { StatementCard, StatementInfo, StatementRow, StatementTitle } from './StyledStatementCard';
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import axios from 'axios';

type StatementCardProps = {
  title: string;
  expectedRevenue: number;
  realRevenue: number;
  isCurrent: boolean; 
};

const monthNames = {
  "01": "Janeiro",
  "02": "Fevereiro",
  "03": "Março",
  "04": "Abril",
  "05": "Maio",
  "06": "Junho",
  "07": "Julho",
  "08": "Agosto",
  "09": "Setembro",
  "10": "Outubro",
  "11": "Novembro",
  "12": "Dezembro"
};

type MonthKey = keyof typeof monthNames;


const StatementCardComponent: React.FC<StatementCardProps> = ({ title, expectedRevenue, realRevenue, isCurrent }) => {

  type ExpenseSummary = {
    [orgId: string]: {
      [month: string]: {
        totalPlannedAmount: string;
        totalRealAmount: string;
      };
    };
  };

  const [transactions, setTransactions] = useState<ExpenseSummary>({});
  const [orgId, setOrgId] = useState(0);
  const [jwtIdToken, setUserJwtIdToken] = useState('');

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUserAttributes = await fetchUserAttributes();
        const orgIdValue = currentUserAttributes['custom:orgId'] || '';
        const orgIdNumber = parseInt(orgIdValue, 10);
        setOrgId(orgIdNumber);

        const currentAuthSession = await fetchAuthSession();
        if (currentAuthSession && currentAuthSession.tokens) {
          const { idToken } = currentAuthSession.tokens;
          if (idToken) {
            setUserJwtIdToken(idToken.toString());
          } else {
            console.log('ID Token is undefined');
          }
        } else {
          console.log('currentAuthSession or tokens are undefined');
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {

    const fetchExpenses = async () => {
      try {

        if (orgId > 0) {
          const response = await axios.get(`https://api.irltech.io/getMonthlySummary?orgId=${orgId}`, {
            headers: {
              Authorization: `${jwtIdToken}`,
            },
          });

          setTransactions(response.data);
        };

      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };
    
    if (jwtIdToken && orgId) {
      fetchExpenses();
    }

  }, [jwtIdToken, orgId]);

  const getCurrentAndLastMonthNumbers = (): [number, number] => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const lastMonth = currentMonth === 1 ? 12 : currentMonth - 1;
  
    return [currentMonth, lastMonth];
  };

  const [currentMonth, lastMonth] = getCurrentAndLastMonthNumbers();
  const displayedMonthNumber = isCurrent ? currentMonth : lastMonth;
  const displayedMonthName = monthNames[displayedMonthNumber.toString().padStart(2, '0') as MonthKey];

  const formattedTitle = `Faturamento - ${displayedMonthName}`;
    
  const calculateTotalOrPlaceholder = (monthNumber: number, isExpected: boolean): string => {
    const monthData = transactions["1"]?.[monthNumber.toString()];
    if (!monthData) {
      return '-';
    }
    const total = Object.values(transactions)
      .flatMap(monthData => Object.values(monthData))
      .reduce((total, expense) => {
        const amount = isExpected ? expense.totalPlannedAmount : expense.totalRealAmount;
        return total + parseFloat(amount.replace('R$', '').replace(',', '.'));
      }, 0);
    return total.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
  };

  const totalExpectedRevenue = calculateTotalOrPlaceholder(displayedMonthNumber, true);
  const totalRealRevenue = calculateTotalOrPlaceholder(displayedMonthNumber, false);
  

  return (
    <StatementCard isCurrent={isCurrent}>
      <StatementTitle>{formattedTitle}</StatementTitle>
      <StatementInfo>
        <StatementRow>
          <span>Faturamento Previsto</span>
          <span>R$ {totalExpectedRevenue}</span>
        </StatementRow>
        <StatementRow>
          <span>Faturamento Real</span>
          <span>R$ {totalRealRevenue}</span>
        </StatementRow>
      </StatementInfo>
    </StatementCard>
  );
};


export default StatementCardComponent;
