import React from 'react';
import SideNav from './components/sidenav/Sidebar'; 
import Header from './components/header/Header'; 
import styled from 'styled-components';

const HEADER_HEIGHT = '60px'; // Example header height
const SIDEBAR_WIDTH = '200px'; // Example sidebar width

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const ContentArea = styled.div`
  display: flex;
  flex-grow: 1;
  margin-top: ${HEADER_HEIGHT}; // Push content area down by the header's height
`;

const Sidebar = styled(SideNav)`
  width: ${SIDEBAR_WIDTH}; // Sidebar width
  flex-shrink: 0; // Prevent the sidebar from shrinking
`;

const MainContent = styled.main`
  flex-grow: 1; // Allow the main content to take up the remaining space
  /* padding: 20px; */
  overflow-y: auto;
  margin-left: ${SIDEBAR_WIDTH}; // Start the main content after the sidebar
`;

const HeaderStyled = styled(Header)`
  height: ${HEADER_HEIGHT};
  width: calc(100% - ${SIDEBAR_WIDTH}); // Adjust width to account for sidebar
  position: fixed;
  top: 0;
  left: ${SIDEBAR_WIDTH}; // Push the header to the right, to start after the sidebar
  z-index: 1000;
  border-bottom: 2px solid lightgrey;
`;

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const orgName = 'Instituto Bratan'; // Replace with actual data

  return (
    <LayoutContainer>
      <HeaderStyled /> 
      <Sidebar />
      <ContentArea>
        <MainContent>{children}</MainContent>
      </ContentArea>
    </LayoutContainer>
  );
};

export default Layout;
