import React, { useEffect, useState, ChangeEvent  } from 'react';
import { Formik, Form, FormikHelpers, FormikProps } from 'formik';

import * as Yup from 'yup';

import { FormValues, subItemsOptions } from './Types';
import { FormContainer, FormGroup, Label, Select, Input, SubmitButton, ButtonFormGroup, ErrorMessage } from './StyledEntriesPage';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


import { getCurrentUser, fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth';

const EntriesPage = () => {

  const navigate = useNavigate();

  const [user, setUser] = useState('');


  const [orgId, setOrgId] = useState(0);
  const orgIdString = orgId.toString();

  const [jwtIdToken, setUserJwtIdToken] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [subItems, setSubItems] = useState<string[]>([]);

  const [initialValues, setInitialValues] = useState({
    expenseType: '',
    date: '',
    expenseSubType: '',
    plannedAmount: '',
    realAmount: '',
    username: '',
    org: ''
  });
   
  useEffect(() => {
    if (selectedType) {
      setSubItems(subItemsOptions[selectedType]);
    } else {
      setSubItems([]);
    }
  }, [selectedType]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = await getCurrentUser();
        setUser(currentUser.username);
        setInitialValues(prevValues => ({ 
          ...prevValues, 
          username: currentUser.username, 
          org: orgIdString 
        }));


        const currentUserAttributes = await fetchUserAttributes();
        const orgIdValue = currentUserAttributes['custom:orgId'] || '';
        const orgIdNumber = parseInt(orgIdValue, 10);
        setOrgId(orgIdNumber);

        const currentAuthSession = await fetchAuthSession();
        if (currentAuthSession && currentAuthSession.tokens) {
          const { idToken } = currentAuthSession.tokens;
          if (idToken) {
            setUserJwtIdToken(idToken.toString());
          } else {
            console.log('ID Token is undefined');
          }
        } else {
          console.log('currentAuthSession or tokens are undefined');
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchUser();
  }, [orgIdString]);

  const handleSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    try {
      await axios.post(`https://api.irltech.io/createExpense?orgId=${orgId}`, values, {
        headers: {
          Authorization: `${jwtIdToken}`,
        },
      });
      alert(`Cadastro realizado com sucesso!`);
      navigate('/expenses');
    } catch (error) {
      alert(`Erro ao enviar a solicitação: ${error}`);
    }
    actions.setSubmitting(false);
  };
  
  const brlCurrencyRegex = /^\d{1,3}(\.\d{3})*,\d{2}$/; // Regex for BRL format
  const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/; // Regex for DD/MM/YYYY format

  const EntrySchema = Yup.object().shape({
    expenseType: Yup.string().required('*O tipo é obrigatório'),
    expenseSubType: Yup.string().required('*O nome é obrigatório'),
    date: Yup.string()
    .matches(dateRegex, 'Formato inválido. Use o formato DD/MM/YYYY')
    .required('A data é obrigatória'),
    plannedAmount: Yup.string()
    .matches(brlCurrencyRegex, 'Formato inválido. Use o formato 1.234,56')
    .required('*O valor planejado é obrigatório'),
  realAmount: Yup.string()
    .matches(brlCurrencyRegex, 'Formato inválido. Use o formato 1.234,56')
    .required('*O valor real é obrigatório'),
  });

  return (
    <FormContainer>
      <h1>Cadastro de despesas</h1>
      <br />
      {user && (
      <Formik
        initialValues={initialValues}
        validationSchema={EntrySchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, setFieldValue, setFieldTouched }: FormikProps<FormValues>) => (
          <Form>
            <FormGroup>
              <Label htmlFor="expenseType">Tipo</Label>
              <Select
                name="expenseType"
                id="expenseType"
                onChange={(e) => {
                  const typeValue = e.target.value;
                  setFieldValue('expenseType', typeValue);
                  setSelectedType(typeValue);
                  setFieldValue('expenseSubType', '');
                }}
              >
                <option value="">Selecione o Tipo</option>
                {Object.keys(subItemsOptions).map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </Select>
              {errors.expenseType && touched.expenseType && <ErrorMessage>{errors.expenseType}</ErrorMessage>}
            </FormGroup>
            
            <FormGroup>
              <Label htmlFor="expenseSubType">Nome</Label>
              <Select
                name="expenseSubType" 
                id="expenseSubType"
                onChange={(e) => {
                  const nameValue = e.target.value;
                  setFieldValue('expenseSubType', nameValue);
                  setFieldTouched('expenseSubType', true, false); 
                }}
              >
                <option value="">Selecione o Nome</option>
                {subItems.map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </Select>
              {errors.expenseSubType && touched.expenseSubType && <ErrorMessage>{errors.expenseSubType}</ErrorMessage>}
            </FormGroup>

            <FormGroup>
              <Label htmlFor="date">Data</Label>
              <Input
                name="date"
                id="date"
                type="text"
                placeholder="DD/MM/YYYY"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('date', e.target.value);
                }}
                onBlur={() => setFieldTouched('date', true, false)}
              />
              {errors.date && touched.date && <ErrorMessage>{errors.date}</ErrorMessage>}
            </FormGroup>

            <FormGroup>
              <Label htmlFor="plannedAmount">Valor Planejado (R$)</Label>
              <Input name="plannedAmount" id="plannedAmount" type="text" placeholder="850,00"/>
              {errors.plannedAmount && touched.plannedAmount && <ErrorMessage>{errors.plannedAmount}</ErrorMessage>}
            </FormGroup>

            <FormGroup>
              <Label htmlFor="realAmount">Valor Real (R$)</Label>
              <Input name="realAmount" id="realAmount" type="text" placeholder="1.250,00"/>
              {errors.realAmount && touched.realAmount ? <ErrorMessage>{errors.realAmount}</ErrorMessage> : null}
            </FormGroup>
            
            <ButtonFormGroup>
              <SubmitButton type="submit">Adicionar</SubmitButton>
            </ButtonFormGroup>
          </Form>
        )}
      </Formik>
      )}
    </FormContainer>
  );
};

export default EntriesPage;
