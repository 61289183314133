export interface FormValues {
  expenseType: string;
  date: string;
  expenseSubType: string;
  plannedAmount: string;
  realAmount: string;
  username: string; 
  org: string;       
  }
  
  export const subItemsOptions: { [key: string]: string[] } = {
    'Custo de investimento': ['Equipamentos', 'Construcao civil', 'Veiculos', 'Mobiliarios', 'Tecnologia da Informacao', 'Marketing'],
    'Custo fixo': ['Aluguel', 'Energia', 'Agua', 'Taxas', 'Telefonia e internet', 'Emprestimo', 'Limpeza e seguranca', 'Servicos recorrentes'],
    'Mao de obra': ['Salario', 'Horas extras', 'Encargos', 'Beneficios', 'Terceirizados', 'Prolabore + Retirada'],
    'Custos variaveis': ['Combustiveis', 'Produtos Limpeza Cafe Escritorio', 'Manutencao', 'Despesas de viagem', 'Tarifas Bancarias', 'Treinamento', 'Servicos Parceiros', 'Insumos', 'Frete'],
    'Material': ['Produtos'],
  };
  