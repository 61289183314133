import styled from 'styled-components';

export const SidebarContainer = styled.div`
  background: #1e2b37;
  color: #fff;
  height: 100vh;
  width: 200px;
  position: fixed;
  left: 0;
  top: 0;
  padding: 20px;
`;

export const SidebarHeader = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100px; 
    height: auto; 
  }
`;



export const SidebarMenu = styled.nav`
  display: flex;
  flex-direction: column;
`;

export const SidebarMenuItem = styled.div`
  padding: 10px 0;
  cursor: pointer;

  &:hover {
    background: #444;
  }
`;

export const SidebarLink = styled.a`
  position: relative; // Needed for absolute positioning of the pseudo-element
  text-decoration: none;
  color: #fff;
  display: block;
  padding: 5px 0; // Adjust padding as needed
  padding-left: 20px; // Make space for the ball
  margin-bottom: 5px; // Space between items

  &::before {
    content: '';
    position: absolute;
    left: 0; // Position the ball on the left edge of the item
    top: 50%;
    transform: translateY(-50%); // Center the ball vertically
    width: 8px; // Size of the ball
    height: 8px; // Size of the ball
    background-color: #fff; // Color of the ball
    border-radius: 50%; // Make it round
    margin-right: 10px; // Space between the ball and the text
  }

  &:hover {
    background-color: #2d4458; // Optional: Change if you want hover effect on the entire row
  }
`;

export const SidebarSection = styled.div`
  margin-top: 20px;
`;

export const SidebarSubmenu = styled.div`
  padding-left: 15px;
  padding-bottom: 15px;
  margin-bottom: 20px; 
`;

export const SidebarMenuHeader = styled.div`
  padding: 10px 0;
  /* font-weight: bold; */
`;

// Plus any additional styling you need for icons or toggles
