import React, { useEffect, useState } from 'react';
import { HeaderContainer, OrgInfo } from './StyledHeader';
import { fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

type HeaderProps = {
  className?: string;
};
 
const Header: React.FC<HeaderProps> = ({ className }) => {
  const [orgId, setOrgId] = useState(0);
  const [jwtIdToken, setUserJwtIdToken] = useState('');
  const [orgName, setOrgName] = useState('');

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUserAttributes = await fetchUserAttributes();
        const orgIdValue = currentUserAttributes['custom:orgId'] || '';
        const orgIdNumber = parseInt(orgIdValue, 10);
        setOrgId(orgIdNumber);

        const currentAuthSession = await fetchAuthSession();
        if (currentAuthSession && currentAuthSession.tokens) {
          const { idToken } = currentAuthSession.tokens;
          if (idToken) {
            setUserJwtIdToken(idToken.toString());
          } else {
            console.log('ID Token is undefined');
          }
        } else {
          console.log('currentAuthSession or tokens are undefined');
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    const storedOrgName = localStorage.getItem('orgName');
    
    if (!storedOrgName) {
      const fetchData = async () => {
        try {
          if (orgId > 0) {

            const response = await axios.get(`https://api.irltech.io/getOrg?orgId=${orgId}`, {
              headers: {
                Authorization: `Bearer ${jwtIdToken}`,
              },
            });
    
            const { orgName: responseOrgName } = response.data;
            localStorage.setItem('orgName', responseOrgName); 
            setOrgName(responseOrgName); 
          }
        } catch (error) {
          console.error('Error making API call:', error);
        }
      };
  
      if (jwtIdToken && orgId) {
        fetchData();
      }
    } else {
      setOrgName(storedOrgName); 
    }
  }, [jwtIdToken, orgId]);
  

  return (
    <HeaderContainer className={className}>
      <div>IRLTECH.IO</div> 
      <OrgInfo>{orgName}</OrgInfo>
    </HeaderContainer>
  );
};

export default Header;
