import styled from 'styled-components';

export const RecentTransactionsContainer = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #fafafa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const AddTransactionButton = styled.button`
  padding: 10px 15px;
  background-color: #1e2b37;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
 
  &:hover {
    background-color: #2d4458;
  }
`;

export const RecentTransactionsTitle = styled.h2`
  margin-bottom: 20px;
`;

export const RecentTransactionsDescription = styled.span`
  font-size: 0.9em; 
  color: #706e6e; 
  display: block; 
  margin-bottom: 20px; 
`;

export const RecentTransactionsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHeader = styled.th`
  padding: 8px;
  text-align: left;
  background-color: #f2f2f2;
  font-weight: normal;
  color: #706e6e;
  background-color: transparent;
`;

export const TableCell = styled.td`
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #eee; // light line for separating rows
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9; // slightly different color for even rows
  }
`;
