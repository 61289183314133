import styled from 'styled-components';

export const RecentTransactionsContainer = styled.div`
  /* margin-top: 20px; */
  padding: 20px;
  background-color: #FFF;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
`;

export const RecentTransactionsTitle = styled.h2`
  margin-bottom: 20px;
`;

export const RecentTransactionsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHeader = styled.th`
  padding: 8px;
  text-align: left;
  background-color: #FFF;
  font-weight: normal;
  color: #706e6e;
  background-color: transparent;
`;

export const TableCell = styled.td`
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #FFF; // light line for separating rows
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #eee; // slightly different color for even rows
  }
`;

export const StyledTableHeader = styled.th`
  border: 1px solid #dddddd;
  text-align: center;
  vertical-align: middle;
  padding: 8px;
  // Add more styles as needed
`;

export const StyledTableCell = styled.td`
  border: 1px solid #dddddd;
  text-align: center;
  vertical-align: middle;
  padding: 8px;
   font-size: smaller;
  // Add more styles as needed
`;

export const StyledTableRow = styled.tr`
  &:nth-child(even) {
    background-color: #FFF; // For zebra striping, if desired
  }
`;