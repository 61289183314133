import styled from 'styled-components';

export const DashboardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px; // This will maintain space between items
  justify-content: center; // This centers the items in the container
  padding: 20px;
`;

export const Card = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-bottom: 16px;
  width: 290px; // Adjust width as needed
`;

export const CardTitle = styled.h3`
  text-align: center;
  margin-bottom: 16px;
`;

export const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardSubtitle = styled.p`
  text-align: center;
  color: #6c757d; // Choose a color that fits your design
  font-size: 0.9em;
  margin-top: 0; // Adjust as needed
`;

export const LegendTable = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: 10px;
`;

export const LegendRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
`;

export const LegendColorBox = styled.span<{ color: string }>`
  height: 10px;
  width: 10px;
  background-color: ${props => props.color};
  margin-right: 5px;
  display: inline-block;
`;

export const LegendLabel = styled.span`
  flex-grow: 1;
`;

export const LegendValue = styled.span`
  flex-grow: 1;
  text-align: right;
`;

export const LegendPercentage = styled.span`
  flex-grow: 1;
  text-align: right;
  margin-left: 10px; // Add spacing between value and percentage
`;