import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard/Dashboard';
import Entries from './pages/RecentTransactions/Entries/EntriesPage';
import RecentTransactionsList from './pages/RecentTransactions/RecentTransactionsList';
import GlobalStyle from './styles/global';
import FinancialOverview from './pages/FinancialOverview/FinancialOverview';
import Layout from './Layout';

import { Amplify } from 'aws-amplify';

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';
Amplify.configure(awsExports);

const App: React.FC = () => {
  return (
    <Authenticator 
      loginMechanisms={['email']}       
      hideSignUp={true}
    >

      <Router>
        <Layout>
          <GlobalStyle />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/entries" element={<Entries />} />
            <Route path="/overview" element={<FinancialOverview />} />
            <Route path="/expenses" element={<RecentTransactionsList />} />
          </Routes>
        </Layout>
      </Router>
      
    </Authenticator>

  );
};

export default App;