import React, { useEffect, useState } from 'react';

import StatementCardComponent from './Statement/StatementCard';
import BudgetSummary from './BudgetSummary/BudgetSummary';
import {
  StyledDashboard,
  DashboardHeader,
  DashboardMain,
  StatementsSection,
  AccountBalanceSection,
  BudgetSummarySection
} from './StyledDashboard';
import IncomeExpensesChart from './IncomeExpenses/IncomeExpensesChart';



const Dashboard = () => {


  // Mock data for statements
  const statementsData = [
    { id: 2, title: 'Faturamento - Outubro/2023', expectedRevenue: 28386, realRevenue: 23726, expectedProfit: 7331.94, realProfit: 6250.30, iscurrent: false },
    { id: 3, title: 'Faturamento - Novembro/2023', expectedRevenue: 23615, realRevenue: 16814, expectedProfit: 6200, realProfit: 4800, iscurrent: true },
  ];

  return (



    <StyledDashboard>

      <DashboardHeader>
        <h1>Previsto x Real</h1>
      </DashboardHeader>

      <DashboardMain>

        <StatementsSection>
          {statementsData.map(statement => (
            <StatementCardComponent
              key={statement.id}
              title={statement.title}
              expectedRevenue={statement.expectedRevenue}
              realRevenue={statement.realRevenue}
              isCurrent={statement.iscurrent}
            />
          ))}
        </StatementsSection>

        <AccountBalanceSection>
          <IncomeExpensesChart />
        </AccountBalanceSection>

        <BudgetSummarySection>
          <BudgetSummary />
        </BudgetSummarySection>

      </DashboardMain>

    </StyledDashboard>
  );
};

export default Dashboard;
